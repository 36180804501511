import { API, logError } from "./index";

const getPartners = async (page) => {
    try {
        return await API().get(`/partners?page=${ page }`);
    } catch (error) {
        return logError(error);
    }
}

const getPartner = async (ID) => {

    try {
        return await API().get(`/partner/${ ID }`);
    } catch (error) {
        return logError(error);
    }
}

const getModules = async (data) => {
    
    let URL = `/modules?partner_id=${data.ID}`;
    if (data.unit) URL = `${URL}&unit_id=${data.unit}`;
    if (data.subUnit) URL = `${URL}&sub_unit_id=${data.subUnit}`;
    if (data.specility) URL = `${URL}&speclity=${data.specility}`;
    if (data.sort) URL = `${URL}&sort=${data.sort}`;
    if (data.page) URL = `${URL}&page=${data.page}`;

    try {
        return await API().get(URL);
    } catch (error) {
        return logError(error);
    }
}

export {
    getPartners,
    getPartner,
    getModules
}