<template>
    <div class="about">
        <div class="page-head partners">
            <div class="container">
                <h1 class="page-title font-weight-bold text-uppercase">
                    {{ $t('partners') }}
                </h1>
                <div class="partner-head-card">
                    <h3 class="font-weight-600 text-brand">{{ $t('partners') }}</h3>
                    <p>
                        Contrary to popular belief, Lorem Ipsum is not simply
                        random text. It has roots in a piece of classical Latin
                        literature from 45 BC, making it over 2000 years old.
                    </p>
                </div>
            </div>
        </div>
        <div class="page-content partners-page-content">
            <div class="container">
                <div v-if="loading" class="row">
                    <div v-for="p in 3" :key="p" class="col-sm-6 col-lg-4 mb-4 h-100">
                        <div class="partner-card">
                            <div class="partner-logo overflow-hidden">
                                <b-skeleton width="100%" height="100%"></b-skeleton>
                            </div>
                            <div>
                                <b-skeleton width="100%" height="7rem"></b-skeleton>
                            </div>
                            <div>
                                <b-skeleton width="100%" height="2rem"></b-skeleton>
                            </div>
                            <div class="my-2">
                                <b-skeleton width="65%" height="2rem"></b-skeleton>
                            </div>
                            <div>
                                <b-skeleton width="100%" height="8rem"></b-skeleton>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else-if="!loading && !partners.length" 
                class="row">
                    <div class="col mx-auto col-lg-10">
                        {{ $t('noResults') }}
                    </div>
                </div>
                <div v-else-if="!loading && partners.length"  class="row align-items-stretch">
                    <div
                        v-for="(partner, index) in partners"
                        :key="index"
                        class="col-sm-6 col-lg-4 mb-4 h-100"
                    >
                        <button
                        @click="checkLogin(partner.id, partner.name)"
                        class="partner-card btn p-0 h-100">
                            <img
                                class="partner-logo"
                                :src="partner.image_logo"
                                :alt="partner.name"
                            />
                            
                            <img
                                class="partner-head-img"
                                :src="partner.image_cover"
                                :alt="partner.name"
                            />
                            <!-- <progressive-img
                            class="partner-head-img"
                            :src="partner.image_cover"
                            :alt="partner.name" /> -->
                            <div
                                class="partner-head d-flex justify-content-end"
                            >
                                <div
                                    class="d-flex align-items-center py-2 px-3"
                                >
                                    <div class="d-flex align-items-center">
                                        <span>Modules </span>
                                        <span class="text-brand mx-2">
                                            {{ partner.moduleCount || 0 }}
                                        </span>
                                    </div>
                                    <div class="d-flex align-items-center">
                                        <span>CME </span>
                                        <span class="text-brand mx-2">
                                            {{ partner.cme || 0 }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="partner-body">
                                <h4 class="partner-title mb-3 text-capitalize">
                                    {{ partner.name }}
                                </h4>
                                <p class="mb-0 partner-desc">
                                    {{ textSummary(partner.description, 120) }}
                                </p>
                            </div>
                        </button>
                    </div>
                </div>
                <!-- Pagination -->
                <div
                    v-if="lastPageID - 1"
                    class="
                        d-flex
                        justify-content-center
                        pt-4
                        pb-1
                        mb-2
                        overflow-hidden
                    "
                >
                    <nav aria-label="Page navigation">
                        <ul class="pagination">
                            <li v-if="!isFirstPage" class="page-item">
                                <button 
                                @click="fetchPage('prev')"
                                class="page-link">
                                {{ $t('prev') }}
                                </button>
                            </li>
                            <li v-for="(page, index) in pagesLinks" :key="index"
                            class="page-item">
                                <button
                                    @click="fetchPage(page.id)"
                                    :class="[currentPage == page.id ? 'active' : '']"
                                    :disabled="currentPage == page.id"
                                    class="page-link">{{ page.id }}</button
                                >
                            </li>
                            <li v-if="!isLastPage" class="page-item">
                                <button 
                                @click="fetchPage('next')"
                                class="page-link">
                                {{ $t('next') }}
                                </button>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>

        <TopFooterAds />
    </div>
</template>

<script>
import { getPartners } from "@/API/partners";
import TopFooterAds from "@/components/TopFooterAds";
import pagination from "@/mixins/pagination";
import textSummary from "@/mixins/textSummary";

export default {
    name: "Partners",
    mixins: [pagination, textSummary],
    components: {
        TopFooterAds,
    },
    data() {
        return {
            loading: true,
            currentPage: 1,
            isFirstPage: true,
            isLastPage: true,
            lastPageID: null,
            pagesLinks: [],
            partners: [],
        };
    },
    methods: {
        getAllPartners() {
            this.loading = true;
            getPartners(this.currentPage).then(res => {
                this.loading = false;
                if (res.status == 200) {
                    const data = res.data?.data;
                    // console.log('partners', data);

                    this.partners = data.partner;

                    if (data?.paginate) {
                        this.pagesLinks = this.pagination(data.paginate, this.currentPage).pagesLinks;
                        this.isFirstPage = this.pagination(data.paginate).isFirstPage;
                        this.isLastPage = this.pagination(data.paginate).isLastPage;
                        this.lastPageID = this.pagination(data.paginate).lastPageID;

                    }

                if (res?.status == 500) {
                    this.$router.push("/500");
                    return;
                }

                }
            });
        },
        fetchPage(id) {

            window.scroll({
                top: 0,
                left: 0,
                behavior: "smooth",
            });

            if (typeof(id) == 'number') {
                this.currentPage = id;
                this.getAllPartners();
            } 

            if (id == 'prev' && !this.isFirstPage) {
                this.currentPage = this.currentPage - 1;
                this.getAllPartners();
            }

            if (id == 'next' && !this.isLastPage) {
                this.currentPage = this.currentPage + 1;
                this.getAllPartners();
            }

        },
        checkLogin(partnerID, partnerName) {
            if (!this.$store.state.auth?.isLogin) {
                this.$root.$emit("bv::show::modal", "must-login-modal");
                return;
            }

            this.$router.push(`/partners/${ partnerID }/${ partnerName }`);
        }
    },
    mounted() {
        this.getAllPartners();
    }
};
</script>

<style lang="scss">
    .partner-title {
        // height: 3.5rem;
    }
    .partner-desc {
        height: 6.4rem;
        // overflow: auto;
    }
</style>